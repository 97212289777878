import { render, staticRenderFns } from "./HomeImageClothing.vue?vue&type=template&id=354dcfc6&scoped=true&"
import script from "./HomeImageClothing.vue?vue&type=script&lang=ts&"
export * from "./HomeImageClothing.vue?vue&type=script&lang=ts&"
import style0 from "./HomeImageClothing.vue?vue&type=style&index=0&id=354dcfc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354dcfc6",
  null
  
)

export default component.exports